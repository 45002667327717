.App {
  font-family: sans-serif;
  text-align: center;
}

.bigCenter {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 10vw;
  color: gray;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

